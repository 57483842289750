import { computed, defineComponent, nextTick, type PropType, ref, watch } from "vue";
import "./GaCollapse.scss";
import { useI18n } from "@/i18n/i18nSetup";
import { assert } from "@utils/assertion";

type GaCollapseState = "SHOWN" | "COLLAPSING" | "HIDDEN";

export const GaCollapse = defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    onUpdateVisible: {
      type: Function as PropType<(show: boolean) => void>,
      required: true,
    },
  },
  setup(props, { slots }) {
    const { t } = useI18n();
    const collapseEl = ref<HTMLElement | null>(null);
    const collapseState = ref<GaCollapseState>(props.visible ? "SHOWN" : "HIDDEN");
    const collapseClasses = computed((): string[] => {
      if (collapseState.value === "HIDDEN") {
        return ["collapse"];
      }
      if (collapseState.value === "COLLAPSING") {
        return ["collapsing"];
      }
      return ["collapse", "show"];
    });

    const show = async (el: HTMLElement): Promise<void> => {
      if (collapseState.value === "COLLAPSING") {
        return;
      }
      el.style.height = "0";
      collapseState.value = "COLLAPSING";
      await nextTick();
      el.style.height = `${el.scrollHeight}px`;
      el.addEventListener(
        "transitionend",
        () => {
          collapseState.value = "SHOWN";
          el.style.height = "";
        },
        { once: true },
      );
    };

    const hide = (el: HTMLElement): void => {
      if (collapseState.value === "COLLAPSING") {
        return;
      }
      el.style.height = `${el.getBoundingClientRect()["height"]}px`;
      collapseState.value = "COLLAPSING";
      //eslint-disable-next-line @typescript-eslint/no-unused-expressions
      el.offsetHeight; // reading offsetHeight is needed for rendering the close animation. The animation can hopefully be replaced by CSS in the future.
      el.style.height = "";
      el.addEventListener(
        "transitionend",
        () => {
          collapseState.value = "HIDDEN";
        },
        { once: true },
      );
    };

    watch(
      () => props.visible,
      async (newVisible) => {
        assert(collapseEl.value !== null);
        if (newVisible) {
          await show(collapseEl.value);
        } else {
          hide(collapseEl.value);
        }
      },
    );

    return () => (
      <div class="ga-collapse">
        <div class="accordion-item">
          <h4 class="accordion-header">
            <button
              class={`accordion-button ga-collapse__header ${collapseState.value === "HIDDEN" ? "collapsed" : ""}`}
              type="button"
              aria-expanded={props.visible ? "true" : "false"}
              aria-controls={`flush-${props.id}`}
              onClick={() => props.onUpdateVisible(!props.visible)}>
              <span class="ga-collapse__header-label">{props.title}</span>
              <span class="ga-collapse__toggle">{props.visible ? t("general:button.close") : t("general:button.open")}</span>
            </button>
          </h4>
          <div id={`flush-${props.id}`} class={`accordion-collapse ${collapseClasses.value.join(" ")}`} ref={collapseEl}>
            <div class="accordion-body">
              <div class="ga-collapse__body">{slots.default?.()}</div>
              <div class="text-end">
                <button type="button" class="btn mt-3" onClick={() => props.onUpdateVisible(!props.visible)} aria-controls={`flush-${props.id}`} aria-expanded={props.visible ? "true" : "false"}>
                  <span class="ga-collapse__toggle">
                    <span class="ico-chevron-up-small"></span>&nbsp;
                    {t("general:button.close")}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
});
