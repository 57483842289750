import { type DeepReadonly, readonly, type Ref, ref } from "vue";
import { assert, assertIsDefined, ensureNonNull, hasProp } from "@utils/assertion";
import cloneDeep from "lodash/cloneDeep";

type IsNullable<T> = Extract<T, null> extends never ? false : true;
const NO_OP = Symbol();

// eslint-disable-next-line no-use-before-define
type UnpackNullResult<T> = IsNullable<T> extends true ? Store<NonNullable<T>> | null : never;

type UnpackUnionResult<State> = State[] extends { kind: string }[]
  ? State extends { kind: infer T }
    ? {
        kind: T;
        // eslint-disable-next-line no-use-before-define
        store: State extends { kind: T } ? Store<State> : never;
      }
    : never
  : never;

export type Store<State> = {
  get: () => DeepReadonly<State>;
  set: (newState: State | DeepReadonly<State>) => void;
  sub: <Key extends Exclude<keyof State, "kind">>(key: Key) => Store<State[Key]>; // We assume key of "kind" is only used for unions. Therefore it never makes sense to create a substore of that property.
  unpackNull: () => UnpackNullResult<State>;
  unpackUnion: () => UnpackUnionResult<State>;
};

function createStoreImpl<State>(read: () => State, write: (getUpdatedValue: (prevState: State) => State | typeof NO_OP) => void): Store<State> {
  function getState(): DeepReadonly<State> {
    const value = read();
    assert(value !== undefined, "'value' should never be undefined.");
    if (value === null) {
      return value as DeepReadonly<State>;
    }
    if (typeof value === "object") {
      return readonly(value) as DeepReadonly<State>;
    }
    return value as DeepReadonly<State>;
  }

  return {
    get: getState,
    set: (newState) => write((_) => cloneDeep(newState as State)),
    sub: (key) =>
      createStoreImpl(
        () => read()[key],
        (getUpdatedValue) => {
          const updatedValue = getUpdatedValue(read()[key]);
          if (updatedValue !== NO_OP) {
            read()[key] = updatedValue;
          }
          return NO_OP;
        },
      ),
    unpackNull: (() => {
      if (read() === null) {
        return null;
      }
      return createStoreImpl(
        () => ensureNonNull(read()),
        (getUpdatedValue) => {
          write((prevState) => {
            assert(prevState !== undefined, "'prevState' should never be undefined.");
            if (prevState === null) {
              throw Error("state has changed in the meantime to `null`");
            }
            return getUpdatedValue(prevState);
          });
        },
      );
    }) as never,
    unpackUnion: (() => {
      const value = ensureNonNull(read());
      assert(typeof value === "object", `'value' must be object to be able to unpack. value: ${value}`);
      assert(hasProp(value, "kind"), `'unpackUnion' can only be called if all types have a 'kind' property. value: ${value}`);
      const kind = value["kind"];
      return {
        kind,
        store: createStoreImpl(read, (getUpdatedValue) => {
          write((prevState) => {
            assertIsDefined(prevState);
            assert(typeof prevState === "object", `'prevState' must be object as it was unpacked as union. prevState: ${prevState}`);
            assert(hasProp(prevState, "kind"), `'prevState' expected to have 'kind' property as it was unpacked as union. prevState: ${prevState}`);
            if (prevState.kind !== kind) {
              throw Error(`State has changed in the meantime to different type of the union. Expected kind: ${kind}, found kind: ${prevState.kind}`);
            }
            return getUpdatedValue(prevState);
          });
        }),
      };
    }) as never,
  };
}

/**
 * Generic function that builds on top of Vue's reactivity.
 *
 * @param initalState initializes the state.
 *
 * @returns store includes the following
 *  - `get` a function that returns the current state.
 *  - `set` a generic set function (replaces the entire state).
 *  - `sub` a function that creates a substore of a property.
 *  - `unpackNull` a function that returns unpacks a possible null value, therefore if it does not return null, it returns a store of a non-nullable state.
 *  - `unpackUnion` a function that returns an object whose property 'kind' can be used to run a type guard against.
 */
export function createStore<State>(initalState: State): Store<State> {
  assert(initalState !== undefined, "'State' should never be undefined.");
  const internalState = ref(cloneDeep(initalState)) as Ref<State>;
  return createStoreImpl(
    () => internalState.value,
    (getUpdatedValue) => {
      const updatedValue = getUpdatedValue(internalState.value);
      if (updatedValue !== NO_OP) {
        internalState.value = updatedValue;
      }
    },
  );
}
